import React, { Component } from 'react';
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from 'utils/media-queries';
import {FONT_SIZE, PRIMARY_WHITE, PRIMARY_GREEN, PRIMARY_GRAY, SECONDARY_GRAY } from 'utils/statics';

const IconCircleLock = ({bg, fg}) => {
    return (
        <IconWrapper>
            <IconBackground bg={bg}>
                <Icon fg={fg} className={'fa fa-lock'} />
            </IconBackground>
        </IconWrapper>
    );
}

export default IconCircleLock;

const IconBackground = styled.div(({bg}) => {
    return css`
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${bg};
        border: 1px solid ${bg};
        border-radius: 50%;
        width: 0.95em;
        height: 0.95em;
    `;
});

export const Icon = styled.i(({fg, size = '0.7rem', padding = '0 0 1px 0'}) => {
    return css`
        position: relative;
        font-size: ${size};
        line-height: ${size};
        padding: ${padding};
        color: ${fg};
    `;
});

const IconWrapper = styled.div`
    font-size: 1rem;
    position: relative;
    padding: 0.1em 1.25em 0.2em 0.5em;
`;

