import React, {Component} from "react";
import {StyledIconButton} from 'utils/shared-styles';

class IconButton extends Component {
    handleClick(event) {
        if (this.props.type !== 'submit') {
            this.props.onButtonClick(event, this.props.action, this.props.value);
        }
    }

    getContent(label, icon) {
        let content =[],
            labelContent = <span key={0}>{label}</span>,
            iconContent = <i key={1} className={icon}/>;

        if(icon) {
            content.push(iconContent);
        }

        if(label) {
            content.push(labelContent);
        }

        return content;
    }
    render() {
        const {className, disabled, label, icon, title, type} = this.props,
            content = this.getContent(label, icon);

        return (
            <StyledIconButton
                type={type || 'button'}
                className={[className, disabled].join(' ')}
                title={title}
                disabled={disabled}
                alt={label}
                onClick={this.handleClick.bind(this)}></StyledIconButton>
        );
    }
}

export default IconButton;