import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Network from "utils/network"
import styled from '@emotion/styled'
import css from '@emotion/css';
import { mq } from 'utils/media-queries';
import { PRIMARY_GREEN, PRIMARY_WHITE, FONT_SIZE, PRIMARY_GRAY } from 'utils/statics';
import { Bubble, BubbleText, PhotoContainer, PhotoWithBubble } from 'utils/shared-styles';
import Button from 'components/button-link';

export default class OurTeam extends Component {

    static propTypes = {

    }

    state = {
        accountants: []
    }

    componentDidMount = async () => {
        try {
            const accountants = await Network().get('api/public/accountants/bios');
            
            this.setState({accountants});            
            
        } catch (error) {
            console.warn(error);
        }
    }

    render() {
        const {accountants} = this.state;

        if (accountants.length === 0) return null;

        return (
            <WrapperOuter>
                <WrapperInner>
                    <Heading>
                        <h2>Our Team</h2>
                        <ActionButtons>
                            <Button href="/public/contact">Contact Us</Button>
                        </ActionButtons>
                    </Heading>
                    {this.state.accountants.map(({firstName, lastName, imageKey, title, info, startYear}, index) => {
                        return (
                            <ServicesContainer key={index}>
                                <PhotoContainerNoGrow>
                                    <PhotoWithBubble src={imageKey}>
                                        <Bubble>
                                            <BubbleText>{firstName}</BubbleText>
                                        </Bubble>
                                    </PhotoWithBubble>
                                </PhotoContainerNoGrow>
                                <Text>
                                    <Name>{firstName} {lastName}</Name>
                                    <Title>{title}</Title>
                                    <Info>{info.replace('%YEARS_EXP%', Number((new Date()).getFullYear()) - Number(startYear))}</Info>
                                </Text>
                            </ServicesContainer>
                        )
                    })}
                </WrapperInner>
            </WrapperOuter>
        )
    }
}

const PhotoContainerNoGrow = styled(PhotoContainer)`
    flex: 0 1 auto;
`;


export const WrapperOuter = styled.div`
    font-size: 1rem;
    display: flex;
    justify-content: center;
`;

export const WrapperInner = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1em;

    ${mq.medium(css`
        padding: 4em;
    `)};
`;

const Text = styled.div`
    display: flex;
    flex-direction: column;
`;
const Heading = styled('div')`  
    grid-area: content;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    align-items: center;
    justify-content: stretch;
    color: ${PRIMARY_GREEN};
    padding-bottom: 2em;

    ${mq.medium(css`
        padding-bottom: 4em;
    `)};
`;


const Name = styled('div')`
    font-size: ${FONT_SIZE.h4m};
    color: ${PRIMARY_GREEN};
    ${mq.medium(css`
        font-size: ${FONT_SIZE.h4};
    `)};
`;

const Title = styled('div')`
    font-size: ${FONT_SIZE.pm};
    color: ${PRIMARY_GREEN};
    ${mq.medium(css`
        font-size: ${FONT_SIZE.p};
    `)};
`;

const Info = styled('div')`
    font-size: ${FONT_SIZE.pm};
    color: ${PRIMARY_GRAY};

    ${mq.medium(css`
        font-size: ${FONT_SIZE.p};
    `)};
`;

const ActionButtons = styled('div')`
    display: flex;
    justify-content: space-between;
    flex: 1 0 auto;
    padding-top: 2em;

    & > div {
        padding: 0 1em;
    }
`;

const ServicesContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2em;

    ${mq.medium(css`
        flex-direction: row;
        padding-bottom: 3em;

    `)};
`;


