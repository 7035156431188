import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq, breakpoints } from 'utils/media-queries';
import {FONT_SIZE, PRIMARY_WHITE, PRIMARY_GREEN, PRIMARY_GRAY, SECONDARY_GRAY } from 'utils/statics';

export const Field = styled.div`
    padding-bottom: 0.5em;
    width: 100%;
    flex: ${({flex = "0 1 auto"}) => flex};
    padding-right: ${({pright = "initial"}) => pright};
    padding-left: ${({pleft = "initial"}) => pleft};

    ${mq.medium(css`
        padding-bottom: 0.5em;
    `)};

    ${mq.large(css`
        padding-bottom: 1em;
    `)};
`;

export const ForgotCredentials = styled.div`
    display: flex;
    justify-content: center;

`;


export const RegisterLinkWrapper = styled.div`
    display: flex;
    justify-content: center;
`;


export const Form = styled.form`
    display: flex;
    flex-direction: column;
    padding: 0.125em;
    width: 15em;
    
    ${mq.medium(css`
        padding: 0.5em;
    `)};

    ${mq.large(css`
        padding: 1em;
    `)};
`;

export const WrapperOuter = styled.div`
    font-size: 1rem;
    display: flex;
    justify-content: center;
`;

export const WrapperInner = styled.div`
    ${mq.medium(css`
        width: 100%;
        max-width: 600px;
        background-color: ${PRIMARY_WHITE};
        padding: 2em 4em;
        border-radius: 0.5em;
        box-shadow: 1px 1px 10px 1px rgba(0,0,0,0.2);
        display: flex;
        flex-direction: column;
        align-items: center;
    `)};
`;

export const Label = styled.label`
    font-size: ${FONT_SIZE.h5};
    color: ${SECONDARY_GRAY};
    display: flex;

    ${mq.medium(css`
        font-size: ${FONT_SIZE.h5m};
    `)};
`;

export const Heading = styled.div(({color}) => {
    return css`
        font-size: ${FONT_SIZE.h2m};
        text-align: center;
        color: ${color || PRIMARY_GREEN};
        display: flex;
        justify-content: center;
        text-transform: uppercase;

        ${mq.medium(css`
            font-size: ${FONT_SIZE.h2};
        `)};
    `;
})

export const Subheading = styled.div`
    font-size: ${FONT_SIZE.h4m};
    text-align: center;
    color: ${PRIMARY_GRAY};
    display: flex;
    justify-content: ${({justify="initial"}) => justify};
    flex: ${({flex="initial"}) => flex};
    width: ${({width="initial"}) => width};
    border-top: ${({bordertop="initial"}) => bordertop};

    ${mq.medium(css`
        font-size: ${FONT_SIZE.h4};
        padding-bottom: 0.25em;
    `)};

    ${mq.large(css`
        font-size: ${FONT_SIZE.h4};
        padding-bottom: 0.75em;
    `)};
`;


export const Input = styled.input`

`;

export const Text = styled.div`
    font-size: ${FONT_SIZE.h5m};
    text-align: center;
    ${mq.medium(css`
        font-size: ${FONT_SIZE.h5};
    `)};
`;