import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { mq } from 'utils/media-queries';
import css from '@emotion/css';

import {PictureLg, PictureSm, Quotes, Quote, Text, Title, Subtitle, Wrapper, WrapperInner} from './section-styles';

const ConsultCPA = props => {
  return (
      <Wrapper>
        <WrapperInner>
            <PictureLg>
                <Family />
            </PictureLg>
            <Text>
                <Title>Consult With Your CPA</Title>
                <Subtitle>When It's Convenient For You</Subtitle>
                <PictureSm>
                    <Family />
                </PictureSm>
                <h5>Have a Question? We Can Help!</h5>
                <Quotes>
                    <Quote>
                        &ldquo;Welcome to WebTax Barbara, I'm Excited to work with you! I see you’ve already uploaded your W2. I’ll start crunching some numbers.&rdquo;<br /> - Liz 
                    </Quote>
                    <Quote>
                        &ldquo;Great Liz, I received some more documents in the mail today, I will upload them now I'm so glad your helping me. This is so convenient!&rdquo;<br /> - Barbara
                    </Quote>
                </Quotes>
            </Text>
        </WrapperInner>
      </Wrapper>
  )
}

ConsultCPA.propTypes = {

}

export default ConsultCPA;


const Family = styled('div')`
    background: url('/images/section_2_home_sm.png') no-repeat center center;
    display: inline-block;
    height: 322px;
    width: 234px;

    ${mq.medium(css`
        background: url('/images/section_2_home_lg.png') no-repeat center center;
        display: inline-block;
        height: 330px;
        width: 454px;
    `)};
`;


