import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Container, LogoWrapper, MenuWrapper, Menu, Item, StyledLink} from 'utils/menu-mobile-styles';
import MenuButton from '../button-menu';
import RegisterLoginButton from '../button-register-signin'
import menuItems from './menu-items';
import Logo from '../logo';


class MenuMobile extends Component {
    
    constructor(props) {
        super(props);

        this.state = {
            open: false
        }
    }

    handleMenuButtonClick = () => {
        this.setState({open: !this.state.open});
    }

    render() {
        const {path} = this.props;

        if (!menuItems) {
            return null;
        }

        return (
            <Container>
                <MenuButton onClick={this.handleMenuButtonClick}/>
                <MenuWrapper open={this.state.open}>
                    <LogoWrapper>
                        <Logo hideTagLine={true} />
                    </LogoWrapper>
                    <RegisterLoginButton path={path} />
                    <Menu>
                        {menuItems.map(link => (
                            <Item key={link.label}>
                                <StyledLink href={link.to}>{link.label}</StyledLink>
                            </Item>        
                        ))}
                    </Menu>
                </MenuWrapper>
            </Container>
        )
    }
}

MenuMobile.propTypes = {

}

export default MenuMobile

