import styled from '@emotion/styled';
import { css } from '@emotion/core';
import {APP_WIDTH, PRIMARY_GREEN, PRIMARY_WHITE, MEDIUM, FONT_SIZE} from 'utils/statics';
import { mq } from 'utils/media-queries';

export const Wrapper = styled('div')`
    grid-area: header;
    position: fixed;
    width: 100%;
    z-index: 20;
`;

export const Header = styled('div')`
    position: relative;
    display: flex;
    flex-direction: column;
    color: ${PRIMARY_WHITE};
    height: 80px;

    ${mq.medium(css`
      height: 95px;
    `)};  

`;


export const Content = styled('div')`
    background-color: ${PRIMARY_GREEN};
    padding-top: 1em;
    padding-bottom: 1em;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

export const Upper = styled('div')`
    display: flex;
    justify-content: space-between;
    max-width: ${APP_WIDTH};
    width: 100%;

    padding-left: 1em;
    padding-right: 1em;
`;
  

