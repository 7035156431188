import React from 'react';
import PropTypes from 'prop-types';
import MenuMobile from './menu-mobile';
import Logo from '../logo';
import Menu from './menu';
import {Wrapper, Header, Content, Upper} from 'utils/header-styles';


const HeaderCompactContainer = props => {
  return (
    <Wrapper>
        <Header>
          <Content>
            <Upper>
              <Logo hideTagLine={true} linkTo={'/'}/>
              <Menu path={props.path}/>            
            </Upper>
          </Content>
        </Header>
        <MenuMobile path={props.path}/>
    </Wrapper>
  )
}

HeaderCompactContainer.propTypes = {

}

export default HeaderCompactContainer


