import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import {PRIMARY_GREEN, PRIMARY_WHITE, MEDIUM, FONT_SIZE} from 'utils/statics';
import { mq, breakpoints } from 'utils/media-queries';
import MenuMobile from './menu-mobile';
import Logo from '../logo';
import Menu from './menu';
import menuItems from './menu-items';


const HeaderContainer = () => {
  return (
    <Wrapper>
        <Header>
          <Background />
          <Content>
            <Upper>
              <Logo linkTo={'/'}/>
              <Menu items={menuItems}/>            
            </Upper>
            <TitleWrapper>
              <Title>Connect With a CPA Today!</Title>
            </TitleWrapper>
          </Content>
        </Header>
        <MenuMobile items={menuItems}/>
    </Wrapper>
  )
}

HeaderContainer.propTypes = {

}

export default HeaderContainer

const Wrapper = styled('div')``;

const Header = styled('div')`
    grid-area: "header";
    position: relative;
    display: flex;
    flex-direction: column;
    color: ${PRIMARY_WHITE};
    height: 100px;

    ${mq.medium(css`
      height: 150px;
    `)};  

    ${mq.large(css`
        height: 300px;
    `)};  
`;

const Background = styled('div')`

    width: 100%;
    height: 100px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;

    background-color: ${PRIMARY_GREEN};
    
    &::after {
      content: "";
      background: url('/images/landing-family-cropped030x.jpg') no-repeat center center;
      background-size: cover;
      height: 100px;
      opacity: 0.25;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      position: absolute;
      z-index: 1;   
    }


    ${mq.small(css`
      &::after {
          background: url('/images/landing-family-cropped030x.jpg') no-repeat center center;
          background-size: cover;
      }
    `)};

    ${mq.medium(css`
      height: 150px;
      &::after {
        height: 150px;
        background: url('/images/landing-family-cropped050x.jpg') no-repeat center center;
        background-size: cover;
      }
    `)};  

    ${mq.large(css`
        height: 300px;
        &::after {
          height: 300px;
          background: url('/images/landing-family-cropped.jpg') no-repeat center center;
          background-size: cover;
        }
    `)};  
`;

const Content = styled('div')`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    ${mq.medium(css`
      padding-top: 1em;
      padding-bottom: 1em;
    `)};  
`;

const Upper = styled('div')`
    display: flex;
    justify-content: center;

    @media only screen and (orientation: landscape) and (min-width: ${breakpoints.large}px) {
      padding: 1em;
    }
`;

const TitleWrapper = styled('div')`
  display: block;

`;
const Title = styled('h1')`
    font-size: ${FONT_SIZE.h5m};
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.04em;
    margin: 0.1em;
    padding: 0.1em;
    color: white;

    @media only screen and (orientation: portrait) and (min-width: ${breakpoints.medium}px) {
      font-size: ${FONT_SIZE.h3m};
    }

    ${mq.large(css`
      font-size: ${FONT_SIZE.h1};
      text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.35);
      letter-spacing: 0.1em;
      margin: 0.5em;
      padding: 0.5em;
    `)};  

`;
