import React from "react";
import {Route} from 'react-router-dom';
import HeaderCompact from './header-unauth';
import Footer from './footer-unauth';
import { Global } from '@emotion/core';
import { Content, WrapperColumn, WrapperRow, globalStyleOverrideBG } from 'utils/layout-styles';
import clientMenuUnauth from './menu-items';
import { PRIMARY_WHITE, PRIMARY_GRAY } from "../../utils/statics";

export default ({component: Component, bg, ...rest}) => {
    return (
        <Route {...rest} render={matchProps => (
            <WrapperRow>
                {bg && <Global styles={globalStyleOverrideBG} />}
                <WrapperColumn>
                    <HeaderCompact path={rest.path} menu={clientMenuUnauth}/>
                    <Content bg={bg = PRIMARY_GRAY}>
                        <Component {...matchProps} />
                    </Content>    
                    <Footer />
                </WrapperColumn>
            </WrapperRow>
        )} />
    )
};

