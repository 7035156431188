import {css} from '@emotion/core';
import { FONT_SIZE, PRIMARY_WHITE, PRIMARY_GRAY, PRIMARY_GREEN, PRIMARY_GREEN_RGB, EL_BORDER_RADIUS, SECONDARY_GRAY } from 'utils/statics';
import { mq } from 'utils/media-queries';

const globalStyles = css`
    body {
        padding: 0;
        margin: 0;
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        color: ${SECONDARY_GRAY};
        background-color: ${PRIMARY_WHITE};

        * {
            box-sizing: border-box;
        }
    }

    h1, h2, h3, h4, h5, 
    h1m, h2m, h3m, h4m, h5m {
        color: ${PRIMARY_GREEN};
    }
    
    [data-clickable="true"] {
        cursor: pointer;
    }

    a {
        color: white;
    }

    .dark-fg {
        a {
            color: ${PRIMARY_GREEN};
        }
    }

    h1 {
        font-size: ${FONT_SIZE.h2m};
        margin: 0.125em 0;
        padding: 0.125em 0;
        
        ${mq.medium(css`
            font-size: ${FONT_SIZE.h1};
            margin: 0.25em 0;
            padding: 0.25em 0;
        `)};  

        ${mq.large(css`
            margin: 0.5em 0;
            padding: 0.5em 0;
        `)};  
    }

    h2 {
        font-size: ${FONT_SIZE.h2m};
        margin: 0.2em 0;
        padding: 0.1em 0;

        ${mq.medium(css`
            font-size: ${FONT_SIZE.h2};
            margin: 0.2em 0;
            padding: 0.1em 0;
        `)};
        
    }

    h3 {
        font-size: ${FONT_SIZE.h3m};
        margin: 0.25em 0;
        padding: 0.25em 0;

        ${mq.medium(css`
            font-size: ${FONT_SIZE.h3};
            margin: 0.25em 0;
            padding: 0.25em 0;
        `)};  
    }

    h4 {
        font-size: ${FONT_SIZE.h4m};
        margin: 0.5em 0;
        padding: 0.5em 0;

        ${mq.medium(css`
            font-size: ${FONT_SIZE.h4};
            margin: 0.25em 0;
            padding: 0.25em 0;
        `)};  
    }

    h5 {
        font-size: ${FONT_SIZE.h5m};
        margin: 0.5em 0;
        padding: 0.5em 0;

        ${mq.medium(css`
            font-size: ${FONT_SIZE.h5};
            margin: 0.25em 0;
            padding: 0.25em 0;
        `)};  
    }

    label {
        font-size: ${FONT_SIZE.h5m};
        margin: 0.125em 0 0 0;
        padding: 0.125em 0 0 0;

        ${mq.medium(css`
            font-size: ${FONT_SIZE.h5};
            margin: 0.5em 0 0.125em 0;
            padding: 0.5em 0 0.125em 0;
        `)};  
    }

    p {
        font-size: ${FONT_SIZE.pm};
        margin: 0.25em 0;
        padding: 0.25em 0;

        ${mq.medium(css`
            font-size: ${FONT_SIZE.p};
            margin: 0.25em 0;
            padding: 0.25em 0;
        `)};  
    }

    small {
        font-size: ${FONT_SIZE.sm};
        margin: 0.25em 0;
        padding: 0.25em 0;

        ${mq.medium(css`
            font-size: ${FONT_SIZE.s};
            margin: 0.25em;
            padding: 0.25em;
        `)};  
    }

    ul, ol {
        font-size: ${FONT_SIZE.h5m};
        ${mq.medium(css`
            font-size: ${FONT_SIZE.h5};
        `)};
        
        li {
            padding-bottom: 0.5em;
        }
    }

    ul {
        list-style: square outside none;
    }

    *:focus {outline: none;}


    select,
    textarea,
    input:not([type="radio"]):not([type="checkbox"]):not(.wt-btn) {
        position: relative;
        border-radius: ${EL_BORDER_RADIUS};
        border: 1px solid ${PRIMARY_GRAY};
        margin-top: 0;
        color: ${SECONDARY_GRAY};
        padding: 6px 8px;
        background-color: white;
        margin: 2px;
        width: 100%;
        font-size: ${FONT_SIZE.h5m};

        ${mq.medium(css`
            font-size: ${FONT_SIZE.h5};
        `)};
    }

    input[type=checkbox]:not(old),
    input[type=radio   ]:not(old) {
        width     : 2em;
        margin    : 0;
        padding   : 0;
        font-size : 1em;
        opacity   : 0;
    }
    
    input[type=checkbox]:not(old) + label,
    input[type=radio   ]:not(old) + label{
        display      : inline-block;
        margin-left  : -2em;
        line-height  : 1.5em;
    }


    input[type=checkbox]:not(old) + label > span,
    input[type=radio   ]:not(old) + label > span,
    input[type=checkbox]:not(old) + input[type=hidden] + label > span,
    input[type=radio   ]:not(old) + input[type=hidden] + label > span  {
        display          : inline-block;
        width            : 0.875em;
        height           : 0.875em;
        margin           : 0.25em 0.5em 0.25em 0.25em;
        border           : 0.0625em solid rgb(192,192,192);
        border-radius    : 0.25em;
        background       : rgb(224,224,224);
        background-image :    -moz-linear-gradient(rgb(240,240,240),rgb(224,224,224));
        background-image :     -ms-linear-gradient(rgb(240,240,240),rgb(224,224,224));
        background-image :      -o-linear-gradient(rgb(240,240,240),rgb(224,224,224));
        background-image : -webkit-linear-gradient(rgb(240,240,240),rgb(224,224,224));
        background-image :         linear-gradient(rgb(240,240,240),rgb(224,224,224));
        vertical-align   : bottom;
    }

    input[type=checkbox]:not(old):checked + label > span,
    input[type=radio   ]:not(old):checked + label > span,
    input[type=checkbox]:not(old):checked + input[type=hidden] + label > span,
    input[type=radio   ]:not(old):checked + input[type=hidden] + label > span  {
        background-image :    -moz-linear-gradient(rgb(224,224,224),rgb(240,240,240));
        background-image :     -ms-linear-gradient(rgb(224,224,224),rgb(240,240,240));
        background-image :      -o-linear-gradient(rgb(224,224,224),rgb(240,240,240));
        background-image : -webkit-linear-gradient(rgb(224,224,224),rgb(240,240,240));
        background-image :         linear-gradient(rgb(224,224,224),rgb(240,240,240));
    }


    input[type=checkbox]:not(old):checked + label > span:before,
    input[type=checkbox]:not(old):checked + input[type=hidden] + label > span:before {
        content     : '✓';
        display     : block;
        width       : 1em;
        color       : ${PRIMARY_GREEN_RGB};
        font-size   : 0.875em;
        line-height : 1em;
        text-align  : center;
        text-shadow : 0 0 0.0714em rgb(115,153,77);
        font-weight : bold;
    }

    input[type=radio]:not(old):checked + label > span > span,
    input[type=radio]:not(old):checked + input[type=hidden] + label > span > span {
        display          : block;
        width            : 0.5em;
        height           : 0.5em;
        margin           : 0.125em;
        border           : 0.0625em solid rgb(115,153,77);
        border-radius    : 0.125em;
        background       : ${PRIMARY_GREEN_RGB};
        background-image :    -moz-linear-gradient(rgb(179,217,140),rgb(153,204,102));
        background-image :     -ms-linear-gradient(rgb(179,217,140),rgb(153,204,102));
        background-image :      -o-linear-gradient(rgb(179,217,140),rgb(153,204,102));
        background-image : -webkit-linear-gradient(rgb(179,217,140),rgb(153,204,102));
        background-image :         linear-gradient(rgb(179,217,140),rgb(153,204,102));
    }

    select {
        -webkit-appearance: none;
    }    

`;

export default globalStyles;
