import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { StyledLink } from 'utils/shared-styles'

const Button = ({href, children, borderColor}) => {

  return (
        <Wrapper>
            <StyledLink href={href} borderColor={borderColor}>
                {children}
            </StyledLink>
        </Wrapper>
  )
}

Button.propTypes = {
    inverted: PropTypes.bool
}

export default Button


const Wrapper = styled('div')`
    display: flex;
    justify-content: center;
`;

