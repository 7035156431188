export const PRIMARY_GREEN = '#00A26F'; // rgb(0, 162, 111)
export const PRIMARY_GREEN_RGB = 'rgb(0, 162, 111)';
export const SECONDARY_GREEN = '#00833F';
export const PRIMARY_WHITE = '#FFFFFF';
export const PRIMARY_GRAY = '#707070';
export const SECONDARY_GRAY = '#505050';
export const TERTIARY_GRAY = '#e0e0e0';

export const PRIMARY_RED = '#a30000';
export const ERROR_TEXT = PRIMARY_RED;

export const FONT_SIZE = {
    h1: '2.5em',
    h1m: '2.0em',
    h2: '2em',
    h2m: '1.75em',
    h3: '1.5em',
    h3m: '1.35em',
    h4: '1.35em',
    h4m: '1.125em',
    h5: '1em',
    h5m: '0.9em',
    p: '0.9em',
    pm: '0.8em',
    s: '0.7em',
    sm: '0.6em'
}

export const EL_BORDER_RADIUS = '5px';
export const COMMON_BORDER_WIDTH = '1.5px';
export const APP_WIDTH = '1500px';