import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { mq } from 'utils/media-queries';
import css from '@emotion/css';
import {PictureLg, PictureSm, Quotes, Quote, Subtitle, Text, Title, Wrapper, WrapperInner} from './section-styles';
import { PRIMARY_GREEN, PRIMARY_WHITE } from 'utils/statics';

const Upload = props => {
  return (
      <WrapperInverted>
        <WrapperInnnerNarrow>
            <Text>
                <Title>Upload Your Tax Documents</Title>
                <Subtitle>Safe and Secure</Subtitle>
                <PictureSm>
                    <UploadAnimation />
                </PictureSm>
                <h5>Have a Question? We Can Help!</h5>
                <Quotes>
                    <Quote>
                        &ldquo;Webtax keeps me so organized. Getting my paperwork to my Accountant is so easy&rdquo;<br /> - BOB M. 
                    </Quote>
                </Quotes>
            </Text>
            <PictureLg>
                <UploadAnimation />
            </PictureLg>
        </WrapperInnnerNarrow>
      </WrapperInverted>
  )
}

Upload.propTypes = {

}

export default Upload;

const WrapperInverted = styled(Wrapper)`
    background-color: ${PRIMARY_GREEN};
    color: ${PRIMARY_WHITE};

    h5,
    ${Title},
    ${Subtitle} {
        color: inherit;
    }

    width: 100%;
    padding-top: 2em;
    padding-bottom: 2em;
`;


const WrapperInnnerNarrow = styled(WrapperInner)`
    
    ${mq.medium(css`
        max-width: 80%; 
    `)};
`;


const UploadAnimation = styled('div')`
    background: url('/images/upload-animation-sm.gif') no-repeat center center;
    display: inline-block;
    height: 130px;
    width: 230px;

    ${mq.medium(css`
        background: url('/images/upload-animation-lg.gif') no-repeat center center;
        display: inline-block;
        height: 187px;
        width: 330px;

    `)};
`;
