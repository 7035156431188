import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '../utils/media-queries';
import { StyledButton } from 'utils/shared-styles';
import { EL_BORDER_RADIUS, PRIMARY_GREEN, FONT_SIZE, PRIMARY_WHITE } from '../utils/statics';
import { Link } from 'react-router-dom';


const Button = ({href, children, borderColor, disabled, type}) => {
  return (
        <Wrapper>
            <StyledButton disabled={disabled} type={type}>
                {children}
            </StyledButton>
        </Wrapper>
  )
}

Button.propTypes = {
    href: PropTypes.string,
    borderColor: PropTypes.string,
    disabled: PropTypes.bool,
    type: PropTypes.string
}

export default Button


const Wrapper = styled('div')`
    display: flex;
    justify-content: center;
`;

