
import React, { Component } from 'react';
import 'url-search-params-polyfill';
import styled from '@emotion/styled'
import css from '@emotion/css';

import { PRIMARY_WHITE, PRIMARY_GREEN, PRIMARY_GRAY, FONT_SIZE } from 'utils/statics';
import {
    Field,
    ForgotCredentials,
    RegisterLinkWrapper,
    Form,
    WrapperOuter,
    WrapperInner,
    Label,
    Heading,
    Input
} from 'utils/form-styles';

import IconButton from "components/button-icon";
import SubmitButton from 'components/button-form';
import IconCircleLock from 'components/icon-circle-lock';
import ErrorMessage from 'components/error-message';
import {getParameterByName} from 'utils/network';
import Link from 'components/link';

export default class Signin extends Component {

    state = {
        fields: {
            username: {
                required: true,
                touched: false,
                error: '',
                value: sessionStorage.getItem('username') || ''
            },
            password: {
                required: true,
                touched: false,
                error: '',
                value: ''
            }
        },
        formError: '',
        formValid: false,
        passwordFieldTypeIsPassword: true
    };

    componentDidMount = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const hasError = urlParams.has('error');
        if (hasError) {
            this.setState({serverError: 'Sign in failed.'});
        }
    }

    handleInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        const fieldUpdate = {...this.state.fields[name]};
        fieldUpdate.value = value;
        fieldUpdate.touched = true;

        this.setState((state) => {
            return {
                fields: {
                    ...state.fields,
                    [name]: fieldUpdate
                }
            }
        }, () => { 
            this.validateField(name, value) 
        });
    }

    validateForm() {
        const fields = this.state.fields;
        let formValid = true;

        for (const fieldName in fields) {
            let field;
            if (fields.hasOwnProperty(fieldName)) {
                field = fields[fieldName];
                if (field.error.length > 0) {
                    formValid = false;
                }
            }
        }

        this.setState({formValid});
    }

    validateField(fieldName, value) {
        const field = {...this.state.fields[fieldName]};
        
        let isValid = false,
            formError = this.state.formError;
      
        switch(fieldName) {
            case 'username':
                isValid = value.length > 0;
                formError = isValid ? '' : 'Username field is empty.';
                break;
            case 'password':
                isValid = value.length > 0;
                formError = isValid ? '' : 'Password field is empty.'
                break;
            default:
                break;
        }

        this.setState((state) => {
            return {
                fields: {
                    ...state.fields,
                    [fieldName]: field
                },
                formError
            }
        }, this.validateForm);
    }

    togglePasswordFieldType = () => {
        this.setState({passwordFieldTypeIsPassword: !this.state.passwordFieldTypeIsPassword})
    }

    handleSubmit = (event) => {
        event.preventDefault()
        if(this.state.formValid) {
            const data = new FormData(this.form);
            sessionStorage.setItem('username', this.state.fields.username.value);
            this.setState({serverError: '', submitted: true});
            
            fetch('/process_login', {
                method: 'POST',
                credentials: "same-origin",
                body: data
            })
            .then(response => {
                const redirectUrl = response.url;

                if (!redirectUrl) return;

                const errorString = getParameterByName('reason', response.url) || 'Sign in failed.';
                if(redirectUrl.indexOf('signin') > 0) {
                    this.setState({submitted: false, serverError: errorString});
                } else {
                    window.location = redirectUrl;
                }
            })
            .catch(error => {
                console.warn(error);
                this.setState({submitted: false, serverError: 'Sign in failed.'});
            })
        }
    }

    render() {
        const {fields, formError, formValid, serverError, submitted, passwordFieldTypeIsPassword} = this.state;

        return (
            <WrapperOuter>
                <WrapperInner>
                    <Heading>Sign In</Heading>
                    <Form onSubmit={this.handleSubmit} ref={fm => {this.form=fm}}>
                        <Field>
                            <Label>User Id / Email</Label>
                            <Input name={'username'} value={fields.username.value} maxlength="50" onChange={this.handleInputChange} />
                        </Field>
                        <Field>
                            <Label>Password <IconCircleLock bg={PRIMARY_GREEN} fg={PRIMARY_WHITE}/></Label>
                            <InputWrapper>
                                <Input type={passwordFieldTypeIsPassword ? 'password' : 'text'} 
                                    name={'password'} 
                                    value={fields.password.value} 
                                    style={{paddingLeft: '1.6em'}}
                                    maxlength="50" 
                                    onChange={this.handleInputChange} />
                                <IconButton 
                                        label="Toggle password visibility" 
                                        className={passwordFieldTypeIsPassword ? 'fal fa-eye' : 'fal fa-eye-slash'}
                                        onButtonClick={this.togglePasswordFieldType}
                                    />
                            </InputWrapper>
                        </Field>
                        <SubmitButton disabled={!formValid || submitted}>
                            <IconCircleLock fg={PRIMARY_GREEN} bg={PRIMARY_WHITE}/>
                            Sign In
                        </SubmitButton>
                        <ErrorMessage>{formError || serverError}</ErrorMessage>
                    </Form>
                    <ForgotCredentials>
                        <Link 
                            href="/lost/username" 
                            color={PRIMARY_GRAY}
                            >Forgot id?
                        </Link> | 
                        <Link 
                            href="/lost/password" 
                            color={PRIMARY_GRAY}
                            >Forgot password?
                        </Link>
                    </ForgotCredentials>
                    <RegisterLinkWrapper>
                        <Link 
                            href={'/create-account'} 
                            color={PRIMARY_GREEN} 
                            transform={'uppercase'} 
                            size={'lg'}>
                            New to Webtax?
                        </Link>
                    </RegisterLinkWrapper>
                </WrapperInner>
            </WrapperOuter>
        )
    }
}

Signin.propTypes = {

}


const InputWrapper = styled('div')`
    display: flex;
    position: relative;

    button {
        position: absolute;
        left: 0;
        top: 0.5em;
        font-size: ${FONT_SIZE.h5};
    }

`;