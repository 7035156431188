import React from "react";
import {Route} from 'react-router-dom';
import HeaderFull from './header-full-unauth';
import Footer from './footer-unauth';
import styled from '@emotion/styled'
import { mq } from 'utils/media-queries'
import { css } from '@emotion/core'
import { baseContent, WrapperColumn, WrapperRow } from 'utils/layout-styles';

export default ({component: Component, ...rest}) => {
    return (
        <Route {...rest} render={matchProps => (
            <WrapperRow>
                <WrapperColumn>
                    <HeaderFull />
                    <Content>
                        <Component {...matchProps} />
                    </Content>
                    <Footer />
                </WrapperColumn>
            </WrapperRow>
        )} />
    )
};


const Content = styled.div`
    ${baseContent};
    padding-top: 2em;

    ${mq.medium(css`
        padding-top: 2em;
    `)};

    ${mq.large(css`
        padding-top: 2em;
    `)};
`;
