
import React from "react";
import {BrowserRouter, Switch, Redirect} from 'react-router-dom';
import {Global} from '@emotion/core';
import GlobalStyles from 'utils/global-styles';
import Landing from './client/app/routes/unauth/landing';
import Signin from './client/app/routes/unauth/signin';
import CreateAccount from './client/app/routes/unauth/create-account';
import LandingLayout from 'components/unauthenticated/layout-unauth-landing';
import Layout from 'components/unauthenticated/layout-unauth';
import VerificationEmailSent from "./client/app/routes/unauth/verification-email-sent";
import AccountantWillContact from "./client/app/routes/unauth/accountant-will-contact";
import TaxServices from "./client/app/routes/unauth/tax-services";
import OurTeam from "./client/app/routes/unauth/our-team";
import { PRIMARY_GRAY } from "../static/js/utils/statics";

const renderRouter = () => (
    <BrowserRouter>
        <div>
            <Global styles={GlobalStyles} />
            <Switch>
                <LandingLayout exact path="/" component={Landing} />
                <Layout exact path="/signin" bg={true} component={Signin} />
                <Layout exact path="/create-account/:accountantName?" bg={true} component={CreateAccount} />
                <Layout exact path="/verification-sent" bg={true} component={VerificationEmailSent} />
                <Layout exact path="/accountant-will-contact" bg={true} component={AccountantWillContact} />
                <Layout exact path="/tax-services" component={TaxServices} />
                <Layout exact path="/our-team" component={OurTeam} />
                <Layout exact path="/error" component={Landing} />
                <Redirect to="/" />
            </Switch>
        </div>
    </BrowserRouter>
);

export {
    renderRouter
};
