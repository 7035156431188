import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/core';
import { mq } from '../utils/media-queries';

const MenuButtonElement = props => {
  return (
    <MenuButton type="button" onClick={props.onClick}>
        <i className="fal fa-bars fa-2x"></i>
    </MenuButton>
  )
}
MenuButtonElement.propTypes = {

}

export default MenuButtonElement


const MenuButton = styled('button')`

    background-color: Transparent;
    background-repeat:no-repeat;
    border: none;
    cursor:pointer;
    overflow: hidden;
    outline:none;
    color: white;
    position: absolute;
    right: 1em;
    top: 2em;
    z-index: 20;

    

`;