import React, { Component } from 'react';
import {
    WrapperOuter,
    WrapperInner,
    Heading,
    Text
} from 'utils/form-styles';

export default () => {
    return (
        <WrapperOuter>
            <WrapperInner>
                <Heading>Thank You!</Heading>
                <Text>A WebTax accountant will be in touch with you.</Text>
            </WrapperInner>
        </WrapperOuter>
    )
}

