import request from './request';

// Default options used for every request
export const getCsrfToken = () => {
  const response = {};
  const token = document.querySelector("meta[name='_csrf']");

  if (token) {
    response['X-CSRF-TOKEN'] = token.getAttribute('content')
  }

  return response;
}

/**
 * @function Network
 * @description Factory function to create a object that can send
 * requests to a specific resource on the server.
 * @param {string} resource The resource used for config
 */
const Network = (resource) => {
  let buildURL = (path = null) => {
    return [
        '',
      path
    ].join('/');
  }

  const defaultOptions = (() => {
    return {
      credentials: 'include',
      headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
          'Cache': 'no-cache',
          ...getCsrfToken(),
      }
    }
  })();

  return {
    getCsrfToken,

    /**
     * @function post
     * @description Make a POST request.
     * @param {string} path
     * @param {object} body
     * @param {object} options
     * @returns {promise}
     */
    post: (path, body, options = {}) => {
      return request(buildURL(path), Object.assign(
        options,
        defaultOptions,
        {
          method: 'POST',
          body: JSON.stringify(body)
        }
      ));
    },


    postFiles: (path, formData) => {
      return request(buildURL(path), Object.assign(
        {
          credentials: 'include',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Cache': 'no-cache',
            ...getCsrfToken(),
          }
        },
        {
          method: 'POST',
          body: formData
        }
      ));
    },

    postLink: (path, formData) => {
      return request(buildURL(path), Object.assign(
        {
          credentials: 'include',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Cache': 'no-cache',
            ...getCsrfToken(),
          }
        },
        {
          method: 'POST',
          body: formData
        }
      ));
    },




    /**
     * @function get
     * @description Make a GET request.
     * @param {string} path
     * @param {object} options
     * @returns {promise}
     */
    get: (path, options = {}) => {
      return request(buildURL(path), Object.assign(
          options,
          defaultOptions,
          { method: 'GET' }
      ));
    },

    /**
     * @function edit
     * @description Make a PUT request.
     * @param {string} path
     * @param {object} body
     * @param {object} options
     * @returns {promise}
     */
    edit: (path, body, options = {}) => {
      return request(buildURL(path), Object.assign(
        options,
        defaultOptions,
        { method: 'PUT' }
      ));
    },

    /**
     * @function delete
     * @description Make a DELETE request.
     * @param {string} path
     * @param {object} options
     * @returns {promise}
     */
    delete: (path, options = {}) => {
      return request(buildURL(path), Object.assign(
        options,
        defaultOptions,
        { method: 'DELETE' }
      ));
    }
  };
};

export default Network;


export const getParameterByName = (name, url) => {
  if (!url) url = window.location.href;
  const decodedUrl = decodeURIComponent(url);
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(decodedUrl);
  if (!results) return null;
  if (!results[2]) return '';
  return results[2].replace(/\+/g, ' ');
}

