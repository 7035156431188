import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { mq } from '../utils/media-queries';
import { FONT_SIZE } from '../utils/statics';

const WtLink = ({href, children, color, size, transform}) => {

    const sizes = {
        sm: {
            mobile: FONT_SIZE.pm,
            desktop: FONT_SIZE.p
        },
        md: {
            mobile: FONT_SIZE.h4m,
            desktop: FONT_SIZE.h4
        },
        lg: {
            mobile: FONT_SIZE.h3m,
            desktop: FONT_SIZE.h3
        }
    }

    const fontSize = sizes[size] || sizes.sm;

    const StyledLink = styled('a')`
        color: ${color};
        font-size: ${fontSize.mobile};
        padding: 0.5em 1em;
        cursor: pointer;
        display: inline-block;
        text-transform: ${transform || 'none'};

        &:visited,
        &:active,
        &.-webkit-any-link {
            color: ${color};
        }

        ${mq.medium(css`
            display: inline-block;
            font-size: ${fontSize.desktop};
        `)};  

        &.active {
            color: white;
        }
    `;
  return (
    <StyledLink href={href}>
        {children}
    </StyledLink>
  )
}

WtLink.propTypes = {
    href: PropTypes.string,
    color: PropTypes.string,
    size: PropTypes.string,
    transform: PropTypes.string
}

export default WtLink

