import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { COMMON_BORDER_WIDTH } from '../utils/statics';

const RegisterLoginButton = ({path = ''}) => {
    let buttonData = {
        label: 'Create Account/Sign In',
        to: '/signin'
    };
    if (path.toLowerCase().indexOf('signin') > -1) {
        buttonData = {
            label: 'Create Account',
            to: '/create-account'
        };
    } else if (path.toLowerCase().indexOf('create') > -1) {
        buttonData = {
            label: 'Sign In',
            to: '/signin'
        };
    }

    return (
        <Wrapper>
            <StyledLink href={buttonData.to}>
                <i className="fas fa-lock"></i>{buttonData.label}
            </StyledLink>
        </Wrapper>
    )
}

RegisterLoginButton.propTypes = {

}

export default RegisterLoginButton


const Wrapper = styled('div')`
    display: flex;
    justify-content: center;
`;

const StyledLink = styled('a')`
    background: transparent;
    border: ${COMMON_BORDER_WIDTH} solid white;
    border-radius: 0.25em;
    color: white;
    text-decoration: none;
    padding: 0.5em 1em;
    cursor: pointer;

    i {
        padding-right: 0.5em;
    }
`;
