
import styled from '@emotion/styled'
import { css } from '@emotion/core';
import { mq } from 'utils/media-queries';
import { PRIMARY_WHITE} from 'utils/statics';

export const MenuWrapper = styled('div')`
    display: none;
    ${mq.large(css`
        display: flex;
        align-items: center;   
    `)};  
`;

export const Menu = styled('ul')(props => {
    return css`

        display: flex;
        flex-direction: column;
        list-style-type: none;
        
        ${mq.medium(css`
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            margin-left: -1px;
            list-style-type: none;
        `)};  
    `;
})


export const Item = styled('li')`
    ${mq.medium(css`
        flex-grow: 1;
        flex-basis: auto;
        margin: .25em 0;
        padding: 0 1em;
        text-align: center;
        border-left: 1px solid #ccc;
        list-item-style: none;

        &:first-of-type {
            border-left: none;
        }    
    `)};  

`;

export const StyledLink = styled('a')`
    text-decoration: none;
    text-transform: uppercase;

    color: ${PRIMARY_WHITE};
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }

`;

