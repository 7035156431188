import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { ERROR_TEXT } from 'utils/statics'
import {css} from '@emotion/core';
import { FONT_SIZE } from 'utils/statics';
import { mq } from 'utils/media-queries';


const ErrorMessage = ({children}) => {
    if (!children) {
        return null;
    }

    return (
        <WrapperOuter>
            <WrapperInner>
            {children}
            </WrapperInner>
        </WrapperOuter>
    )
}

ErrorMessage.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object
    ])
}

const WrapperOuter = styled.div`
    font-size: ${FONT_SIZE.pm};
    padding: 0.125em 0 0 0;
    text-align: center;
    color: ${ERROR_TEXT};
    text-align: left;

    ${mq.medium(css`
        font-size: ${FONT_SIZE.p};
        padding: 0.25em 0 0 0;
    `)};  

    ${mq.large(css`
        padding: 1em 0 0 0;
    `)};  
`;

const WrapperInner = styled.div`
    
`;

export default ErrorMessage
