const menu = [
    {
        label: 'Tax Services',
        to: '/tax-services'
    },
    {
        label: 'Our Process',
        to: '/#our-process'
    },
    {
        label: 'Pricing',
        to: '/#pricing'
    },
    {
        label: 'Our Team',
        to: '/our-team'
    },
    {
        label: 'Contact Us',
        to: '/public/contact'
    }
];

export default menu;
