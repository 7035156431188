
import styled from '@emotion/styled'
import { css } from '@emotion/core';
import { mq } from 'utils/media-queries';
import { PRIMARY_WHITE, PRIMARY_GREEN, COMMON_BORDER_WIDTH, FONT_SIZE} from 'utils/statics';

export const Container = styled('div')`
    ${mq.large(css`
        display: none;
    `)};  
`;

export const LogoWrapper = styled('div')`
    padding-bottom: 2em;
`;

export const MenuWrapper = styled('div')(props => {
    return css `
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;

        display: ${props.open ? 'flex' : 'none'};
        flex-direction: column;
        width: 100%;
        height: 100vh;
        background-color: ${PRIMARY_GREEN};
        padding-top: 1em;
    `;
})

export const Menu = styled('ul')`
    display: flex;
    flex-direction: column;
    
    list-style-type: none;
    margin: 0;
    padding 0;

    & > li,
    & > a {
        border-bottom: ${COMMON_BORDER_WIDTH} solid ${PRIMARY_WHITE};
    } 
`;

    
export const Item = styled('li')`
    text-align: center;
    padding-top: 2em;
    
`;

export const StyledLink = styled('a')`
    text-decoration: none;
    color: ${PRIMARY_WHITE};
    cursor: pointer;
    font-size: ${FONT_SIZE.h4};

    &:hover {
        text-decoration: underline;
    }
`;
