import React from 'react';
import {
    WrapperOuter,
    WrapperInner,
    Heading,
    Text
} from 'utils/form-styles';

export default () => {
    return (
        <WrapperOuter>
            <WrapperInner>
                <Heading>Thank You!</Heading>
                <Text>A verification email has been sent!</Text>
                <Text>Please click Finalize Account in the email to finish your account setup.</Text>
            </WrapperInner>
        </WrapperOuter>
    )
}
