import styled from '@emotion/styled'
import {css} from '@emotion/core';
import { FONT_SIZE, PRIMARY_WHITE, PRIMARY_GRAY, PRIMARY_GREEN, EL_BORDER_RADIUS, SECONDARY_GRAY } from 'utils/statics';
import { mq } from 'utils/media-queries';
import { Link } from 'react-router-dom';

export const buttonStyle = (borderColor, inline, small) => {
    return css`
        background: ${PRIMARY_GREEN};
        border-radius: ${EL_BORDER_RADIUS};
        color: ${PRIMARY_WHITE};
        font-size: ${FONT_SIZE.h5m};
        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        padding: 0.75em 0.75em;
        cursor: pointer;
        display: flex;
        justify-content: center;
        width: 100%;
        ${inline ? '': 'min-width: 10em;'};
        ${borderColor ? 'border: 1px solid' + borderColor : ''};

        &.disabled,
        &[disabled] {
            opacity: 0.5;
        }

        ${mq.medium(css`

            font-size: ${FONT_SIZE.h5};
            ${inline ? '': 'min-width: 10em;'};
            max-width: 16em;

        `)};  
    `;
}

export const StyledLinkRR = styled(Link)(({inline, borderColor}) => {
    return css`${buttonStyle(borderColor, inline)};`;
});

export const StyledLink = styled.a(({inline, borderColor}) => {
    return css`${buttonStyle(borderColor, inline)};`;
});

export const StyledButton = styled.button(({inline, borderColor}) => {
    return css`${buttonStyle(borderColor, inline)};`;
});

export const InvisibleButton = styled.button`
    background-color: Transparent;
    background-repeat:no-repeat;
    border: none;
    cursor:pointer;
    overflow: hidden;
`;

export const StyledIconButton = styled(InvisibleButton)`
    font-size: 1.5em;
    color: ${PRIMARY_GREEN};
`;


export const WrapperOuter = styled.div`
    font-size: 1rem;
    display: flex;
    justify-content: center;
`;

export const WrapperInner = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    
`;

export const PhotoContainer = styled('div')`
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    text-align: center;
    padding: 1em;
`;


export const PhotoWithBubble = styled('div')(({path='/images/accountants/', src}) => {
    return `
        background: url('${path + src}-sm.png') no-repeat center center;
        height: 140px;
        width: 140px;

        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        box-shadow: 0 5px 5px 2px rgba(0,0,0,0.4);
        border-radius: 50%;
        border: none;
    `;
});

export const Bubble =  styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    background-color: ${PRIMARY_GREEN};
    font-size: ${FONT_SIZE.sm};
    position: relative;
    top: 10%;

    box-shadow: 0 5px 5px 2px rgba(0,0,0,0.4);

    ${mq.medium(css`
        font-size: ${FONT_SIZE.sm};
    `)};
`;

export const BubbleText = styled.div`
    color: ${PRIMARY_WHITE};

`;