import {PRIMARY_GREEN, PRIMARY_WHITE, MEDIUM} from 'utils/statics';
import { FONT_SIZE } from 'utils/statics';
import styled from '@emotion/styled'
import css from '@emotion/css';
import { mq, breakpoints } from 'utils/media-queries';

export const Wrapper = styled('div')`
    display: flex;
    justify-content: center;
    width: 100%;
`;

export const WrapperInner = styled('div')`
    display: flex;
    flex-wrap: nowrap;
    flex: 1 1 auto;

    ${mq.large(css`
        max-width: 80%;
        padding: 2em;
    `)};  

    @media only screen and (orientation: landscape) and (min-width: ${breakpoints.large}px) {
        max-width: 100%;
    }
    

`;

export const Title = styled.h2`
    font-weight: normal;
`;

export const Subtitle = styled.h5`
    font-weight: 100;
    position: relative;
    top: -1em;
`;

export const Text = styled('div')`
    flex: 1 1 auto;
    text-align: center;
    
    ${mq.medium(css`
        flex: 1 1 50%;
        text-align: left;
    `)};  
    
`;

export const Quotes = styled.div`
    padding: 1em;
    text-align: left;
`;

export const Quote = styled.blockquote`
    padding: 0.5em;
    font-size: ${FONT_SIZE.p};
    font-style: italic;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 40px;
    margin-inline-end: 40px;

    ${mq.medium(css`
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
    `)};  

`;

export const PictureLg = styled('div')`
    flex: 1 1 auto;
    display: none;

    ${mq.medium(css`
        display: block;
        padding: 0.5em;
    `)};  

`;

export const PictureSm = styled('div')`
    flex: 1 1 auto;
    justify-content: center;
    
    ${mq.medium(css`
        display: none;
    `)};  

`;

