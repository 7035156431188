import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import {PRIMARY_GREEN, PRIMARY_WHITE, SMALL, MEDIUM, FONT_SIZE, APP_WIDTH} from 'utils/statics';
import { mq } from 'utils/media-queries';


const FooterContainer = props => {
    return (
        <Footer>
            <LogoWrapper>
                <Link href="#" onClick={(e) => {
                    e.preventDefault(); 
                    window.location.href=('/');
                }}>
                    <LogoFooter />
                </Link>

                
            </LogoWrapper>
            <InfoWrapper>
                <Info>
                    <InfoItem>Contact Us</InfoItem>
                    <InfoItem><a href="tel: 7705449458">770-544-9458</a></InfoItem>
                    <InfoItem><a href="mailto: contact@webtax.tax">contact@webtax.tax</a></InfoItem>
                    <InfoItem>
                        <address>
                            <dl>
                                <dt>WebTax</dt>
                                <dt>3650 Canton Rd</dt>
                                <dt>Marietta, GA 30066</dt>
                            </dl>
                        </address>
                    </InfoItem>
                </Info>
                <Info>
                    <InfoItem>WebTax</InfoItem>
                    <InfoItem><a href="/public/privacy">Privacy Policy</a></InfoItem>
                    <InfoItem><a href="/public/faq">FAQ</a></InfoItem>
                    <InfoItem><a href="/public/privacy">Become a WebTax<br />Accountant</a></InfoItem>
                </Info>
                <Info>
                    <InfoItem>Taxes</InfoItem>
                    <InfoItem><a href="/tax-services">Tax Services</a></InfoItem>
                    <InfoItem><a href="/public/process">Our Process</a></InfoItem>
                    <InfoItem><a href="/public/free-quote">Free Quote</a></InfoItem>
                    <InfoItem><a href="/#pricing">Pricing</a></InfoItem>
                </Info>
                <Info>
                    <InfoItem>Follow Us</InfoItem>
                    <InfoItem><a href="https://twitter.com/web_tax">Twitter</a></InfoItem>
                    <InfoItem><a href="https://www.facebook.com/WebTax.tax">Facebook</a></InfoItem>
                    <InfoItem><a href="https://www.instagram.com/webtax/">Instagram</a></InfoItem>
                </Info>
            </InfoWrapper>
        </Footer>
    )
}

FooterContainer.propTypes = {

}

export default FooterContainer

const Link = styled.a`
    
`;

const InfoWrapper = styled('div')`
    display: flex;
    justify-content: space-around;
    padding-top: 1em;
`;

const Info = styled('ul')`
    list-style-type: none;
    margin: 0;
    padding-left: 1.1em;
    padding-right: 1.1em;
    display: flex;
    flex-direction: column;

    font-size: ${FONT_SIZE.s};

    ${mq.medium(css`
        font-size: ${FONT_SIZE.p};
        padding-left: 2em;
        padding-right: 2em;
    `)}

    ${mq.medium(css`
        padding-left: 4em;
        padding-right: 4em;
    `)}

    &:first-of-type {
        padding-left: 0;
    }

    &:last-of-type {
        padding-right: 0;
    }
    
    
`;

const InfoItem = styled('li')`
    &:first-of-type {
        text-transform: uppercase;
    }

    padding-bottom: 0.5em;
`;

const LogoWrapper = styled('div')`
    padding-bottom: 1em;
    border-bottom: 1px solid ${PRIMARY_WHITE};
    
    display: flex;
    justify-content: center;

    width: 100%;
    min-width: 22em;
    max-width: ${APP_WIDTH};


    ${mq.large(css`
        width: 75%;
    `)};
    

`;

const LogoFooter = styled('div')(props => {
    return css`
        background: url('/images/wt-logo-white-sm-adj.png') no-repeat;
        width: 122px;
        height: 44px;
        
        ${mq.medium(css`
            background: url('/images/wt-logo-white-md-adj.png') no-repeat;
            width: 150px;
            height: 56px;
        `)};
      
    `;
})


const Footer = styled('div')`
    grid-area: footer;
    width: 100%;
    padding: 0.5em;

    ${mq.medium(css`
        padding: 1em;
    `)};
    
    position: relative;
    background-color: ${PRIMARY_GREEN};
    
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
        text-decoration: none;
        color: white;
        
        &:hover,
        &:active,
        &:visited {
            text-decoration: underline;
        }
    }

    color: ${PRIMARY_WHITE};
    

`;

