import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { mq, breakpoints } from '../utils/media-queries';
import { FONT_SIZE, PRIMARY_WHITE } from '../utils/statics';

const ImgLogoLink = ({linkTo}) => {
    return (
        <Link href="#" onClick={(e) => {
            e.preventDefault(); 
            window.location.href=(linkTo);
        }}>
            <ImgLogo />
        </Link>
    )
}


const LogoContainer = ({hideTagLine, linkTo}) => {
    const show = hideTagLine ? false : true;

    return (
        <Container>
            { linkTo ? <ImgLogoLink linkTo={linkTo} /> : <ImgLogo /> }
            <TagLine show={show}>The Future of Professional Tax Services</TagLine>
        </Container>
    )
}

LogoContainer.propTypes = {

}

export default LogoContainer


const Container = styled('div')`
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const ImgLogo = styled('div')(props => {
    return css`
        background: url('/images/wt-logo-white-sm-adj.png') no-repeat;
        width: 122px;
        height: 44px;

        @media only screen and (orientation: portrait) and (min-width: ${breakpoints.large}px) {
            background: url('/images/wt-logo-white-lg-adj.png') no-repeat;
            width: 155px;
            height: 62px;
        }
        
       
      
    `;
})

const Link = styled.a`
    
`;

const TagLine = styled('h4')(props => {
    return css`
        font-size: ${FONT_SIZE.pm};
        font-weight: normal;
        margin: 0;
        padding: 0;
        display: ${props.show ? 'block' : 'none'};
        color: ${PRIMARY_WHITE};

        ${mq.xLarge(css`
            font-size: ${FONT_SIZE.p};
            
        `)};
    `
});
