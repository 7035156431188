import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import css from '@emotion/css';
import { mq } from 'utils/media-queries';
import { PRIMARY_GREEN, PRIMARY_GRAY } from 'utils/statics';
import Button from 'components/button-link';

class TaxServices extends Component {

    render() {
        return (
            <WrapperOuter>
                <WrapperInner>
                    <Heading>
                        <h2>Tax Services</h2>
                        <ActionButtons>
                            <Button href="/create-account">Get Started</Button>
                            <Button href="/public/free-quote">Free Quote</Button>
                        </ActionButtons>
                    </Heading>
                    <ServicesContainer>
                        <Icon>
                            <i className="fal fa-hand-holding-usd"></i>
                        </Icon>
                        <Text>
                            <h3>Accounting Services</h3>
                            <p>Our accountants can help keep your books up to date and in compliance with accounting standards. We are Quickbooks proficient and also familiar with many other accounting systems.</p>
                            <ul>
                                <li>Input and/or review transactions</li>
                                <li>Repair Account Receivable and Accounts Payable</li>
                                <li>Reconcile your bank and credit card accounts</li>
                                <li>Make adjusting year-end journal entries</li>
                                <li>Prepare accurate and timely financial statements</li>
                            </ul>
                        </Text>
                    </ServicesContainer>
                    <ServicesContainer>
                        <Icon>
                            <i className="far fa-file-user"></i>
                        </Icon>
                        <Text>
                            <h3>Personal Tax Services</h3>
                            <p>WebTax has over 25 years experience in preparing individual tax returns. Our CPAs, Enrolled Agents, and Professional Tax Preparers can handle most all tax scenarios and situations, including:</p>
                            <ul>
                                <li>Home Purchase or Sale and other Property Transactions</li>
                                <li>Investment Income from Dividends/Stock Sales/Capital gains</li>
                                <li>Rental Properties</li>
                                <li>Small Business/Independent Contractor Income</li>
                                <li>Affordable Care Act Tax Implications</li>
                                <li>Alternative Minimum Tax Situations</li>
                                <li>FREE e-filing and direct deposit services</li>
                            </ul>
                        </Text>
                    </ServicesContainer>
                    <ServicesContainer>
                        <Icon>
                            <i className="fal fa-person-dolly-empty"></i>
                        </Icon>
                        <Text>
                            <h3>Business Tax Services</h3>
                            <p>WebTax prepares Business Tax Returns for a wide variety of companies. Our tax professionals can prepare the following returns:</p>
                            <ul>
                                <li>S-Corporations</li>
                                <li>1120S Returns</li>
                                <li>C-Corporations</li>
                                <li>1120 Returns</li>
                                <li>Partnerships</li>
                                <li>1065 Returns</li>
                                <li>Sole Proprietors</li>
                                <li>Schedule C 1040 Returns</li>
                                <li>Estates and Trusts</li>
                                <li>1041 Returns</li>
                                <li>Non-Profit Organizations</li>
                                <li>990 Returns</li>
                            </ul>
                        </Text>
                    </ServicesContainer>
                </WrapperInner>
            </WrapperOuter>
        )
    }
}

TaxServices.propTypes = {

}

export default TaxServices;

export const WrapperOuter = styled.div`
    font-size: 1rem;
    display: flex;
    justify-content: center;
`;

export const WrapperInner = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 1em;

    ${mq.medium(css`
        padding: 0 2em;
    `)};
`;

const Heading = styled('div')`  
    grid-area: content;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    align-items: center;
    justify-content: stretch;
    color: ${PRIMARY_GREEN};
    padding-bottom: 2em;

    ${mq.medium(css`
        padding-bottom: 4em;
    `)};
`;

const Icon = styled('div')`
    border-radius: 50%;
    background-color: rgba(0, 162, 111, 0.5);
    width: 200px;
    height: 200px;
    flex: 1 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 6em;
    margin: 0.25em;
`;

const Text = styled('div')`
    flex: 1 1 auto;
    color: ${PRIMARY_GRAY};

    ${mq.medium(css`
        padding: 0 2em;
    `)};
`;

const ActionButtons = styled('div')`
    display: flex;
    justify-content: space-between;
    flex: 1 0 auto;
    padding-top: 2em;

    & > div {
        padding: 0 1em;
    }
`;

const ServicesContainer = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 2em;


    ${mq.medium(css`
        flex-direction: row;
        padding-bottom: 3em;

        &:nth-of-type(odd) {
            flex-direction: row;   
        }
    `)};
`;


