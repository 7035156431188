import React from 'react'
import PropTypes from 'prop-types'
import {MenuWrapper, Menu, Item, StyledLink} from 'utils/menu-styles';
import RegisterSigninButton from '../button-register-signin';
import menuItems from './menu-items';

const MenuContainer = ({path}) => {
    return (
        <MenuWrapper>
            <Menu>
                {menuItems.map(link => (
                    <Item key={link.label}>
                        <StyledLink href={link.to}>{link.label}</StyledLink>
                    </Item>        
                ))}
            </Menu>
            <RegisterSigninButton path={path}></RegisterSigninButton>
        </MenuWrapper>
    )
}

MenuContainer.propTypes = {
    items: PropTypes.array
}

export default MenuContainer
