import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import css from '@emotion/css';
import { mq } from 'utils/media-queries';
import {Quotes, Quote, Subtitle, Title, Wrapper, WrapperInner} from './section-styles';
import { PRIMARY_GREEN, PRIMARY_WHITE } from 'utils/statics';
import Button from 'components/button-link';
import WtLink from 'components/link';

const Upload = props => {
  return (
      <WrapperInverted>
        <WrapperInnnerNarrow>
            <Text>
                <Title>Pricing</Title>
                <ButtonWrapper>
                    <Button borderColor={PRIMARY_WHITE} href={'/public/free-quote'}>Free Quote</Button>
                </ButtonWrapper>
                <ButtonWrapper>
                    <Button borderColor={PRIMARY_WHITE} href={'/create-account'}>Get Started</Button>
                </ButtonWrapper>
                <ButtonWrapper>
                    <WtLink href={'/tax-services'}>View additional tax services ></WtLink>
                </ButtonWrapper>
                <ButtonWrapper>
                    <WtLink href={'/public/contact'}>Have a Business? We Can Help!</WtLink>
                </ButtonWrapper>
            </Text>
            <Picture>
                <CliffGirl />
            </Picture>
            <Text>
                <HighLight>Secure Document Storage with year round access</HighLight>
                <HighLight>Video and Phone Conferencing available</HighLight>
                <HighLight>Accounting and Tax Advice, all year long</HighLight>
                <HighLight>Ideal for Citizens Living Abroad</HighLight>
                <HighLight>No Appointments required</HighLight>
            </Text>
        </WrapperInnnerNarrow>
      </WrapperInverted>
  )
}

Upload.propTypes = {

}

export default Upload;



export const Text = styled('div')`
    flex: 1 1 auto;
    text-align: center;
`;

const ButtonWrapper = styled.div`
    padding: 1em;
`;

const Disclaimer = styled.p`
    font-weight: 300;
`;

const LargeText = styled.h1`
    font-size: 6em;
    font-weight: 400;
    margin: 0;
    padding: 0;
    color: ${PRIMARY_WHITE};
`;

export const Picture = styled('div')`
    flex: 1 1 auto;
    justify-content: center;
    text-align: center;
`;


const WrapperInverted = styled(Wrapper)`
    background-color: ${PRIMARY_GREEN};
    color: ${PRIMARY_WHITE};

    High,
    ${Title},
    ${Subtitle} {
        color: inherit;
    }

    width: 100%;
    padding-top: 2em;
    padding-bottom: 2em;
    margin-bottom: 0.5em;
`;



export const HighLight = styled.h5`
    color: ${PRIMARY_WHITE};
    font-weight: 100;
`;


const WrapperInnnerNarrow = styled(WrapperInner)`
    flex-direction: column;
    ${mq.medium(css`
        max-width: 42%;    
    `)};
`;


const CliffGirl = styled('div')`
    background: url('/images/cliff-girl-sm.png') no-repeat center center;
    display: inline-block;
    height: 234px;
    width: 234px;

    ${mq.medium(css`
        background: url('/images/cliff-girl-lg.png') no-repeat center center;
        height: 330px;
        width: 330px;
    `)};
`;
