import styled from '@emotion/styled'
import { SECONDARY_GRAY, APP_WIDTH, PRIMARY_WHITE, TERTIARY_GRAY } from "./statics";
import { css } from '@emotion/core'
import { mq } from 'utils/media-queries'

export const baseContent = css`
    grid-area: content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1 1 auto;
    width: 100%;
    min-width: 0;
    max-width: ${APP_WIDTH};
    padding: 0.5em;
    padding-top: 6em;
    align-self: flex-start;
    justify-self: center;

    ${mq.medium(css`
        padding: 1.25em;
        padding-top: 7em;
    `)};

    ${mq.large(css`
        padding: 3em;
        padding-top: 8em;
    `)};
`;

export const Content = styled.div`
    ${baseContent};
`;

export const WrapperRow = styled("div")(({justify = 'center', align = 'initial', padding = 'initial', flex = '1 1 auto'}) => {
    return `
        align-items: ${align};    
        display: flex;
        flex: ${flex};
        justify-content: ${justify};
        min-width: 0;
        padding: ${padding};
        width: 100%;
        
    `;
});

export const WrapperCol = styled("div")((
        {justify = 'center', align = 'center', padding = 'initial', flex = '1 1 auto'}
    ) => {
    return `
        display: flex;
        flex-direction: column;
        justify-content: ${justify};
        align-items: ${align};
        min-width: 0;
        padding: ${padding};
        width: 100%;
        flex: ${flex};
    `;
});

export const bodyGrid = css`
    display: grid;
    flex-direction: column;
    flex: 1 1 auto;
    
    min-width: 0;

    grid-template-areas:
    "header"
    "content"
    "footer";

    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr auto;
    grid-gap: 0;

    height: 100vh;

`;

export const WrapperColumn = styled("div")`
    ${bodyGrid};
`;


export const globalStyleOverrideBG = css`
    body {
        ${mq.medium(css`
            background-color: ${TERTIARY_GRAY};
        `)};
    }
`;