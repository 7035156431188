import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { mq } from 'utils/media-queries';
import Button from 'components/button-link';
import css from '@emotion/css';
import {PictureLg, PictureSm, Text, Title, Wrapper, WrapperInner} from './section-styles';

const Convenience = props => {
  return (
      <Wrapper>
        <WrapperInner>
          <Text>
            <Title>Experience the Convenience of Online Accountants!</Title>
            <PictureSm>
                <HeadShotsGrouped />
            </PictureSm>
            <h5>It’s like having an Accountant right in your pocket.</h5>
            <p>Develop a long term relationship with your Webtax Accountant, year after year all from the convenience of your PC, Phone or Tablet.</p>
            <ButtonWrapper>
                <Button href={'/create-account'}>Get Started</Button>
            </ButtonWrapper>
          </Text>
          <PictureLg>
            <HeadShotsGrouped />
          </PictureLg>
        </WrapperInner>
      </Wrapper>
  )
}

Convenience.propTypes = {

}

export default Convenience;


const ButtonWrapper = styled.div`
    padding: 1em;
`;

const HeadShotsGrouped = styled('div')`
    background: url('/images/section_1_home_sm.png') no-repeat center center;
    height: 170px;
    width: 234px;
    display: inline-block;
    
    ${mq.medium(css`
        background: url('/images/section_1_home_lg.png') no-repeat center center;
        height: 272px;
        width: 374px;
    `)};  

    
`;