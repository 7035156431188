import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'

import { mq } from 'utils/media-queries';
import css from '@emotion/css';
import {PictureLg, PictureSm, Quotes, Quote, Subtitle, Text, Title, Wrapper, WrapperInner} from './section-styles';

const ViewProgress = props => {
  return (
      <WrapperTaller>
        <WrapperInner>
            <PictureLg>
                <Monitor />
            </PictureLg>
            <Text>
                <Title>View Your Progress</Title>
                <Subtitle>Your Accountant keeps you updated Through Your Dashboard</Subtitle>
                <PictureSm>
                    <Monitor />
                </PictureSm>
                <h5>Have a Question? We Can Help!</h5>
                <Quotes>
                    <Quote>
                        &ldquo;Webtax is so convenient. I logged in with my phone, signed my returns, and my accountant filed it. THANKS Webtax!&rdquo;<br /> - Emily M. 
                    </Quote>
                </Quotes>
            </Text>
        </WrapperInner>
      </WrapperTaller>
  )
}

ViewProgress.propTypes = {

}

export default ViewProgress;

const WrapperTaller = styled(Wrapper)`
    padding-top: 2em;
    padding-bottom: 2em;
`;

const Monitor = styled('div')`
    background: url('/images/monitor-sm.png') no-repeat center center;
    display: inline-block;
    height: 187px;
    width: 200px;

    ${mq.medium(css`
        background: url('/images/monitor-lg.png') no-repeat center center;
        display: inline-block;
        height: 267px;
        width: 285px;
    `)};
`;
