import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import css from '@emotion/css';
import { mq } from 'utils/media-queries';
import { PRIMARY_GREEN, PRIMARY_WHITE, FONT_SIZE } from 'utils/statics';

import Convenience from './section-convenience';
import ConsultCPA from './section-consult-cpa';
import ViewProgress from './section-view-progress';
import Pricing from './section-pricing';
import Upload from './section-upload';


class LandingPage extends Component {

    $refs = {
        ourProcess: {
            name: 'our-process'
        },
        pricing: {
            name: 'pricing'
        }
    }

    locateAnchor = () => {
        const refs = this.$refs;
        for(const key in this.$refs) {
            if (refs.hasOwnProperty(key)) { 
                const ref = refs[key];
                if (ref.el && location.href.includes(ref.name)) {
                    setTimeout(() => {
                        ref.el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
                    }, 100);
                    
                }
            }            
        }
    }

    componentDidUpdate() {
        this.locateAnchor();
    }

    componentDidMount() {
        setTimeout(this.locateAnchor);
    }

    render() {
        return (
            <Landing>
                <Convenience />
                <ConsultCPA />
                <Separator >
                    <a ref={ref => this.$refs.ourProcess.el = ref}>Our Process</a>
                </Separator>
                <Upload />
                <ViewProgress />
                    <a ref={ref => this.$refs.pricing.el = ref}></a>
                <Pricing />
            </Landing>
        )
    }
}

LandingPage.propTypes = {

}

export default LandingPage;



const Landing = styled('div')`  
    grid-area: content;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    align-items: center;
    justify-content: stretch;
    color: ${PRIMARY_GREEN};
    width: 100%;
`;

const Separator = styled('div')`
    display: flex;
    justify-content: center;

    font-size: ${FONT_SIZE.h3};
    margin-top: 1em;
    margin-bottom: 1em;

    ${mq.medium(css`
      font-size: ${FONT_SIZE.h2};
    `)};

    padding-top: 0.25em;
    padding-bottom: 0.25em;
    color: ${PRIMARY_WHITE};
    background-color: ${PRIMARY_GREEN};
    width: 100%;
    flex: 1 1 auto;
`;